import React, { useState, useEffect } from "react";

import ErrorPage from './components/ErrorPage';
import LoadingPage from './components/LoadingPage';
import FourOhFourPage from "./components/FourOhFourPage";
import DisableWifiPage from "./components/DisableWifiPage";
import CompletePage from "./components/CompletePage";

import Pace from 'react-pace-progress';

import { silentAuthenticationPlus, isMobileDevice } from 'honeybadgersilentauthplus'

import './App.css';


function App() {

  const [ initialLoadingDone, setInitialLoadingDone ] = useState(false);
  const [ initialLoadError, setInitialLoadError ] = useState(false);
  const [ gotSettings, setGotSettings ] = useState(false);
  const [ settings, setSettings ] = useState({});

  const [ completed, setCompleted ] = useState(false);

  const [ success, setSuccess ] = useState(false);
  const [ failed, setFailed ] = useState(false);
  const [ errored, setErrored ] = useState(false);

  const [ notMobile, setNotMobile ] = useState(false);
  const [ notMobileData, setNotMobileData ] = useState(false);

  const [ useSmsOtp, setUseSmsOtp ] = useState(false);

  const [ missingReqs, setMissingReqs ] = useState(false);

  const [ customerid, setCustomerid ] = useState('');
  const [ hbtoken, setHbtoken ] = useState('');
  const [ phonenumber, setPhonenumber ] = useState('');

  const [ isMobile, setIsMobile ] = useState(false);

  useEffect(() => {

    // declare the async data fetching function
    const fetchData = async () => {

      //console.log({isMobileDevice})
      //console.log({silentAuthenticationPlus})

      let thisismobile = await isMobileDevice();
      setIsMobile(thisismobile)

      console.log(window.location.href);     //yields: "https://silent.hbauth.io/?hbtoken=534860556&customerid=hbinit-saonly&demo=true"
      console.log(window.location.pathname); //yields: "/?hbtoken=534860556&customerid=hbinit-saonly&demo=true" (where snippets run)

      const urlParams = new URLSearchParams(window.location.search);
      let thiscustomerid = urlParams.get('customerid');
      const thishbtoken = urlParams.get('hbtoken');
      let thisphonenumber = urlParams.get('phonenumber');

      // now, phone number won't have the + sign in front of it -- we stripped it out to send it across in the querystring, and that's fine -- i'm going to build support for checking for it and adding it in in the library...

      console.log({thiscustomerid, thishbtoken, thisphonenumber});
      setHbtoken(hbtoken);

      if(thiscustomerid === null && hbtoken === null){
        console.error('definitely need both a customerid and an hbtoken, error...');

        // but if they've given us a customerid, might as well go ahead and at least skin it for them
        setMissingReqs(true);

      } else {
        // get the data from the api
        console.log(`api: ${process.env.REACT_APP_APIENDPOINT}`);

        let initialdata, initialjson;

        let customeridlookup = false;
        // do we have a customerid?
        // not going to add the initial lookup for customer settings to the json library as it doesn't make any sense -- the only reason we do a lookup here is so we can pull in their settings and theme this thing appropriately.
        // IF they are doing their own hosting, they obviously will know their own settings, so they won't need to do a lookup
        // but, will use the library for the rest of this functionality, so we can test it out and make sure all those pieces work.
        if(thiscustomerid === null){
          customeridlookup = true;
          console.log(`no customerid, will look up by the domain host: ${window.location.host}`)
          initialdata = await fetch(`${process.env.REACT_APP_APIENDPOINT}/api/v1/auth/silent/settings/domain?lookup=${window.location.host}`);
          // convert the initialdata to json
          initialjson = await initialdata.json();
          
        } else {
          console.log(`we had a customerid [${thiscustomerid}], using it to get settings`);
          setCustomerid(thiscustomerid);
          initialdata = await fetch(`${process.env.REACT_APP_APIENDPOINT}/api/v1/auth/silent/settings/${thiscustomerid}`);
          // convert the initialdata to json
          initialjson = await initialdata.json();
        }

        console.log({initialjson});

        if(initialjson.hasOwnProperty('success') && initialjson.success === true && initialjson.hasOwnProperty('settings')){
          // set state with the result
          setSettings(initialjson.settings);
          setGotSettings(true);

          if (customeridlookup) thiscustomerid = initialjson.settings.customerid;
          setCustomerid(thiscustomerid);

          if(thiscustomerid !== null && thishbtoken === null){
            console.error('had a customerid, but no hbtoken, might as well at least skin the error message for them');
    
            // but if they've given us a customerid, might as well go ahead and at least skin it for them
            setMissingReqs(true);

          } else {
            // is there anything else we need to do with the initial stuff?
            // update page title
            // update favicon

            // we can carry on -- what's next?
            // need to check mobile device && on wifi

            //console.log({thisismobile});
            if(thisismobile){
              console.log('device is mobile, can continue');
              
              // let's go ahead and set up an thissa object
              let thissa = silentAuthenticationPlus(thiscustomerid);

              setInitialLoadingDone(true);

              // ok so finally, we are here, and we know that we can actually do the thing...
              
              // initialize.... initiate(precheck, precheckconfig, phone, token)
              let initresults = await thissa.initiate(false, null, phonenumber, thishbtoken);
              console.log({initresults});
              
              // and now, subscribe for a result...
              /*
              let statusresults = thissa.initializeStatusConnection(thishbtoken, authProcessComplete);
              console.log({statusresults});
              */

              // now, initresults should hvae a redirecturl in it...
              if(initresults.hasOwnProperty('redirecturl')){
                console.log('following a get..');
                // need to follow the redirect url...
                let redirectreults = await thissa.executeSa(initresults.redirecturl, 'get');
                console.log({redirectreults});

                // this should give us true/false
                authProcessComplete(redirectreults.result);
              }

              if(initresults.hasOwnProperty('actionurl')){
                console.log('following a post..');
                // need to follow the redirect url...
                let redirectreults = await thissa.executeSa(initresults.actionurl, 'post');
                console.log({redirectreults});

                console.log('submitting the response');
                let resulturl = `${initresults.reportresulturl}?id=${initresults.queryid}&token=${redirectreults.data.sessionId}`;
                console.log({resulturl});
                let reportresults = await thissa.reportResponse(resulturl);

                console.log({reportresults});

                // this should give us true/false
                authProcessComplete(reportresults.result);
              }

              // use the library
              //const ismobiledatadata = await fetch(`${process.env.REACT_APP_APIENDPOINT}/api/v1/auth/silent/mode`);
              // no longer trusting this thing....
              /*
              const isusingmobiledatadata = await thissa.isConnectedViaMobileData();
              console.log({isusingmobiledatadata});

              // convert the ismobiledatadata to json
              // don't need to do this anymore, since we are now using the library
              //const ismobiledatadatajson = await ismobiledatadata.json();

              //console.log({ismobiledatadatajson});

              if(isusingmobiledatadata){
                console.log('device is connected via mobile data, can continue');

                setInitialLoadingDone(true);

                // ok so finally, we are here, and we know that we can actually do the thing...
                
                // initialize.... initiate(precheck, precheckconfig, phone, token)
                let initresults = await thissa.initiate(false, null, phonenumber, thishbtoken);
                console.log({initresults});
                
                // and now, subscribe for a result...
                let statusresults = thissa.initializeStatusConnection(thishbtoken, authProcessComplete);
                console.log({statusresults});

                // now, initresults should hvae a redirecturl in it...
                if(initresults.hasOwnProperty('redirecturl')){
                  // need to follow the redirect url...
                  let redirectreults = await thissa.followRedirectUrl(initresults.redirecturl);
                  console.log({redirectreults});
                }
                
              } else {
                console.warn('device is NOT connected via mobile data, need to prompt them to turn it off');

                if(initialjson.settings.hasOwnProperty('allowsmsotpfailover') && initialjson.settings.allowsmsotpfailover === true){
                  console.info('settings do allow for SMS OTP failover');
                } else {
                  console.info('settings do NOT allow for SMS OTP failover');
                }

                setNotMobileData(true);

                // need to do something to listen for a change in status....
                const finallyconnected = await thissa.pollForMobileDataStatus();
                console.log({finallyconnected});

                if(finallyconnected){
                  console.log('no longer on wifi, can continue');

                  // how do we toggle off the wifi detected thing?
                  setInitialLoadingDone(true);
                  setNotMobileData(false);
                  
                  // initialize.... initiate(precheck, precheckconfig, phone, token)
                  let initresults = await thissa.initiate(false, null, phonenumber, thishbtoken);
                  console.log({initresults});
                  
                  // and now, subscribe for a result...
                  let statusresults = thissa.initializeStatusConnection(thishbtoken, authProcessComplete);
                  console.log({statusresults});

                  // now, initresults should hvae a redirecturl in it...
                  if(initresults.hasOwnProperty('redirecturl')){
                    // need to follow the redirect url...
                    let redirectreults = await thissa.followRedirectUrl(initresults.redirecturl);
                    console.log({redirectreults});
                  }

                } else {
                  console.log('something happened and we do not have a response, just error?');
                }

              }
              */

            } else {
              console.log('device is NOT mobile, go no further');
              setNotMobile(true);
            }
          }
          

        } else {
          console.error(`got a bad response from the server... cannot continue`);
          setInitialLoadError(true);
        }
      }
        
    }
  
    // call the function
    fetchData()
      // make sure to catch any error
      .catch((error) => {
        console.error(error);
        setInitialLoadError(true);
      });
  }, []);

  function authProcessComplete(){
    console.log('authProcessComplete called!');
    setCompleted(true);
    setInitialLoadingDone(false);
  }

  async function sendSmsOtp(){
    console.log('sendSmsOtp called');
    //callApi(null, `/api/v1/auth/silent/sms/issue`, {phone: params.userPhone.replace(/\+/g, ''), token: params.tokenInQuery}, 'post', `request sms otp instead of silent auth`, process.env.REACT_APP_API);
  }

  // check out the url -- does it have all that we need?

  // pull customerid so we can get branding

  switch(true){
    case initialLoadError:
      // in this case, we haven't been able to get to the api, so we can't really allow any theme to be applied -- will just be the generic message.
      return <ErrorPage message={"Something went wrong. Please try scanning the QR code again."} settings={settings} isMobile={isMobile} type={'initialloaderror'} />
    //break;

    // 404
    case missingReqs:
      // if we have a customerid but no hbtoken, at least it will get their colors and a custom message, if desired.
      return <FourOhFourPage settings={settings} isMobile={isMobile} />
      //return <ErrorPage message={"These aren't the drones you are looking for."} settings={settings} isMobile={isMobile} />
    //break;

    case gotSettings && initialLoadingDone:
      return <LoadingPage message={settings.hasOwnProperty('loading') && settings.loading.hasOwnProperty('message') ? settings.loading.message : "We're verifiying your identity."} settings={settings} isMobile={isMobile} />
    //break;

    case gotSettings && completed:
      return <CompletePage message={settings.hasOwnProperty('complete') && settings.complete.hasOwnProperty('message') ? settings.complete.message : `Identity verification check complete. Please return to continue.`} settings={settings} isMobile={isMobile} />
    //break;
    
    /*
    case completed && success:
      return (
        <div className="App">
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh"}}>
            All good!  Carry on my wayward son
          </div>
        </div>
      );
    //break;

    case completed && failed:
      return (
        <div className="App">
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh"}}>
            If at first you don't succeed.... 
          </div>
        </div>
      );
    //break;
    */
    
    // no difference to the user -- still have to go back, so just give them complete
    /*
    case gotSettings && errored:
      return <ErrorPage message={"Sorry, something went wrong.  Try again later."} settings={settings}/>
    //break;
    */

    case gotSettings && notMobile:
      return <ErrorPage message={settings.hasOwnProperty('error') && settings.error.hasOwnProperty('notmobile') ? settings.error.notmobile : "We've detected you're not using a mobile device.  Please scan the QR code from the mobile device linked to your account."} settings={settings} isMobile={isMobile} type={'notmobile'} />
    //break;

    case gotSettings && useSmsOtp:
      return (
        <div className="App">
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh"}}>
            <div>You can enter your one time passcode here.</div>
            <div>Maybe you can request a new one here.</div>
          </div>
        </div>
      );
    //break;

    case gotSettings && notMobileData:
      return <DisableWifiPage settings={settings} isMobile={isMobile} sendSmsOtp={sendSmsOtp}/>
    //break;
    
    default:
      return (
        <div className="App" style={{width: '100vw', height: '100vh'}}>
          <Pace color="#000000"/>
        </div>
      );
    //break;
  }

}

export default App;
