import React from 'react'
import PageTemplate from './PageTemplate';
import * as AllSpinners from "react-spinners";
import {v4 as uuidv4} from 'uuid';

const LoadingPage = (params) => {
  
  function loader() {
    switch(params.Loader){
      case "BarLoader":
        return (
          <AllSpinners.BarLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "BeatLoader":
        return (
          <AllSpinners.BeatLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "BounceLoader":
        return (
          <AllSpinners.BounceLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "CircleLoader":
        return (
          <AllSpinners.CircleLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "ClimbingBoxLoader":
        return (
          <AllSpinners.ClimbingBoxLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "ClipLoader":
        return (
          <AllSpinners.ClipLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "ClockLoader":
        return (
          <AllSpinners.ClockLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "DotLoader":
        return (
          <AllSpinners.DotLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "FadeLoader":
        return (
          <AllSpinners.FadeLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "GridLoader":
        return (
          <AllSpinners.GridLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "HashLoader":
        return (
          <AllSpinners.HashLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "MoonLoader":
        return (
          <AllSpinners.MoonLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "PacmanLoader":
        return (
          <AllSpinners.PacmanLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;
      
      case "PropagateLoader":
        return (
          <AllSpinners.PropagateLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "PulseLoader":
        return (
          <AllSpinners.PulseLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "RingLoader":
        return (
          <AllSpinners.RingLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "RiseLoader":
        return (
          <AllSpinners.RiseLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "RotateLoader":
        return (
          <AllSpinners.RotateLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "ScaleLoader":
        return (
          <AllSpinners.ScaleLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "SkewLoader":
        return (
          <AllSpinners.SkewLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "SquareLoader":
        return (
          <AllSpinners.SquareLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      case "SyncLoader":
        return (
          <AllSpinners.SyncLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;

      //PuffLoader
      default:
        return (
          <AllSpinners.PuffLoader key={uuidv4()} color={params.hasOwnProperty('loadercolor') ? params.loadercolor : '#000000'}/>
        )
      //break;
    }

  }

  function mainBody() {
    return (
      <div key={uuidv4()} className={'text-box'}>
        {params.message}
      </div>
    )
  }
  
  return <PageTemplate pageBody={[loader(), mainBody()]} settings={params.settings} isMobile={params.isMobile}  />

}

export default LoadingPage;