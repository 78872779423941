import React from 'react'
import { Helmet } from "react-helmet";
import GoogleFontLoader from 'react-google-font-loader';
import {v4 as uuidv4} from 'uuid';

import Logo from '../assets/logo.svg'
import { ToastContainer } from "react-toastify";

const PageTemplate = (params) => {
    let fonts = [{font: 'Poppins', weights: [400]}];

    if(params.hasOwnProperty('settings') && params.settings.hasOwnProperty('pagefontfamily')) fonts[0].font = params.settings.pagefontfamily;
    if(params.hasOwnProperty('settings') && params.settings.hasOwnProperty('pagefontweight')) fonts[0].weights[0] = params.settings.pagefontweight;

    const styleObj = {}
    if(params.hasOwnProperty('settings') && params.settings.hasOwnProperty('pagebgcolor')) styleObj.backgroundColor = params.settings.pagebgcolor;
    styleObj.fontFamily = fonts[0].font;

    //if()

    return (
        <div className="App" style={styleObj}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{params.hasOwnProperty('settings') && params.settings.hasOwnProperty('pagetitle') ? params.settings.pagetitle : 'Silent Authentication+'}</title>
                <link rel="icon" href={params.hasOwnProperty('settings') && params.settings.hasOwnProperty('favicon') ? params.settings.favicon : "https://hbukdevstorage.blob.core.windows.net/qrcodehandler/favicons/favicon.ico"} />
            </Helmet>
            <ToastContainer />
            <GoogleFontLoader fonts={fonts} />
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh"}}>
                <img src={params.hasOwnProperty('settings') && params.settings.hasOwnProperty('logo') ? params.settings.logo : Logo} alt="Identity Checked" style={{marginBottom: 'unset', height: 'auto', width: params.isMobile ? '80vw' : '35vw'}} />
                {/*<img src="logo.png" alt="Identity Checked" />*/}

                <div className="divider"></div>

                {params.hasOwnProperty('pageBody') && params.pageBody.map((ele, index) => {
                    //console.log(`getting ready to return index ${index}`);
                    return <div key={uuidv4()} style={{margin: index > 0 ? '25px 25px' : '25px 0px', fontSize: params.isMobile ? '1.1em' : '1.5em'}}>{ele}</div>;
                })}

                <footer>
                    Powered by Silent Authentication+
                </footer>
            </div>
        </div>
      );
}

export default PageTemplate;