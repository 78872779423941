import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../lotties/wifi-off.json';
import { Button } from 'reactstrap';
import { TbReload } from 'react-icons/tb';
import { toast } from 'react-toastify';
import PageTemplate from './PageTemplate';
import {v4 as uuidv4} from 'uuid';

import 'react-toastify/dist/ReactToastify.css';

const DisableWifiPage = (params) => {
	console.log(`disable wifi view called with params: `, params);

    const iconsection = () => {
        // friendly reminder: you can use https://lottiefiles.com/tools/json-editor to find good start/end frames from a json file
        const defaultOptions = {
            loop: false,
            autoplay: true,
            initialSegment:[17, 59],
            animationData: animationData,
            rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div key={uuidv4()}>
                <Lottie options={defaultOptions}
                  height={params.isMobile ? 200 : 200}
                  width={params.isMobile ? 200 : 200}
                  isStopped={false}
                  isPaused={false}
                />
            </div>
        )
    }

    const mainBody = () => {
        // friendly reminder: you can use https://lottiefiles.com/tools/json-editor to find good start/end frames from a json file

        const reloadPage = () => {
            console.log('reloadPage called.');
            // This method takes an optional parameter which by default is set to false. If set to true, the browser will do a complete page refresh from the server and not from the cached version of the page.
            window.location.reload(false);
        }

        const sendSmsOtp = () => {
            console.log("sendSmsOtp called.");

            // here we need to do something to issue an sms otp and then get the user over to a page where we cand do something with it... maybe for now just pop up an alert...
            //toast("This feature is not yet enabled.", {position: "top-center", type: "warning"});

            // now, do we already have a phone number?  or do we need to collect it?
            if(params.userPhoneValid){
                console.log('we already had a valid user phone... just send an sms...');
                params.sendSmsOtp();

                // i don't think any other state change is necessary, since userPhoneValid is set, we should bypass the collection screen and display the sms input instead...
                // actually, i think we need number submitted too?  but i think we pick that up somewhere else

            } else {
                console.log('we do not have a valid phone number for the user, need to collect it');
            }

            params.setShowSmsOtp(true);
            params.setBypassDisableWifi(true);
        }

        return (
            <div key={uuidv4()}>
                <div className={'text-box'}>
                    <div style={{textAlign: "center", margin: '20px'}}>We've detected you're connected to a WiFi network.  Please disconnect from WiFi to continue.</div>
                    {params.settings.hasOwnProperty('allowsmsotpfailover') && params.settings.allowsmsotpfailover === true && <div style={{textAlign: "center", marginTop: "10px"}}>Alternatively, you may request an SMS OTP passcode by clicking <Button className={'linkbutton'} onClick={() => sendSmsOtp(params.userPhone.replace(/\+/g,''))}>here</Button>.</div>}
                    {false && params.showRetryButton && <div>
                        <Button className='btnMain' color='primary' block style={{display: "flex", alignItems: "center", marginTop: '10px', padding: '7.5px', visibility: 'visible', fontSize: params.isMobile ? "17pt" : "17pt"}} onClick={() => reloadPage()}>
                            <div>Reload</div>
                            <TbReload style={{marginLeft: '5px'}}/>
                        </Button>
                    </div>}
                </div>

            </div>
        )
    }

    return <PageTemplate pageBody={[iconsection(), mainBody()]} settings={params.settings} isMobile={params.isMobile} />
}

export default DisableWifiPage