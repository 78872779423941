import React from 'react'
import PageTemplate from './PageTemplate';
import {v4 as uuidv4} from 'uuid';
//import AnimatedCheckmark, { MODES } from 'react-animated-checkmark';
import CircularProgress from './Checkmark';
import { useMotionValue } from "framer-motion"

const CompletePage = (params) => {
  let progress = useMotionValue(90)

  function checkmark() {
    return (
      <div key={uuidv4()}>
        {/*<AnimatedCheckmark 
          mode={MODES.SUCCESS}
          size={params.isMobile ? 128 : 512}
          breathingEllipsis={false}
          collapseFactor={0}
          baseColor={'#000000'}
          successColor={'#0e0e0e'}
        />*/}
        
        <CircularProgress progress={progress} settings={params.settings} isMobile={params.isMobile} />
      </div>
    )
  }

  function mainBody() {
    return (
      <div key={uuidv4()} className={'text-box'}>
        {params.message}
      </div>
    )
  }
  
  return <PageTemplate pageBody={[checkmark(), mainBody()]} settings={params.settings} isMobile={params.isMobile} />

}

export default CompletePage;