import React from 'react'
import PageTemplate from './PageTemplate';
import {v4 as uuidv4} from 'uuid';

const ErrorPage = (params) => {

  // may need to style differently depending on params.type

  function mainBody() {
    return (
      <div key={uuidv4()} className={'text-box'} style={{margin: '20px'}}>
        {params.message}
      </div>
    )
  }
  
  return <PageTemplate pageBody={[mainBody()]} settings={params.settings} isMobile={params.isMobile} />

}

export default ErrorPage;