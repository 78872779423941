import React from 'react'
import { Helmet } from "react-helmet";
import GoogleFontLoader from 'react-google-font-loader';

const FourOhFourPage = (params) => {

  let fonts = [{font: 'Poppins', weights: [400]}];

  if(params.hasOwnProperty('settings') && params.settings.hasOwnProperty('pagefontfamily')) fonts[0].font = params.settings.pagefontfamily;
  if(params.hasOwnProperty('settings') && params.settings.hasOwnProperty('pagefontweight')) fonts[0].weights[0] = params.settings.pagefontweight;

  const styleObj = {}
  if(params.hasOwnProperty('settings') && params.settings.hasOwnProperty('pagebgcolor')) styleObj.backgroundColor = params.settings.pagebgcolor;
  styleObj.fontFamily = fonts[0].font;

  if(params.isMobile) styleObj.fontSize ='1.5em';
  if(params.isMobile) styleObj.textAlign ='center';

  styleObj.width = '100vw';
  styleObj.height = '100vh';
  styleObj.display = 'flex';
  styleObj.justifyContent = 'center';
  styleObj.alignItems = 'center';

  return (
      <div className="App" style={styleObj}>
          <Helmet>
              <meta charSet="utf-8" />
              <title>{params.hasOwnProperty('settings') && params.settings.hasOwnProperty('pagetitle') ? params.settings.pagetitle : 'Silent Authentication+'}</title>
              <link rel="icon" href={params.hasOwnProperty('settings') && params.settings.hasOwnProperty('favicon') ? params.settings.favicon : "https://hbukdevstorage.blob.core.windows.net/qrcodehandler/favicons/favicon.ico"} />
          </Helmet>
          <GoogleFontLoader fonts={fonts} />
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: params.isMobile ? "75vw" : "100vw", height: "100vh"}}>
              {params.hasOwnProperty('settings') && params.settings.hasOwnProperty('fourohfourmessage') ? params.settings.fourohfourmessage : "The page you're looking for can't be found."}
          </div>
      </div>
    );

}

export default FourOhFourPage;